<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('coupon.update')"
      :title="$t('coupon.update')"
      @submitPressed="submitPressed"
    />
    <coupons-form
      v-if="coupon"
      :couponObject="coupon"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CouponsForm from "./components/CouponsForm.vue";

export default {
  components: {
    TitleBar,
    CouponsForm,
  },
  data() {
    return {
      coupon: null,
      action: null,
    };
  },
  created() {
    this.loadCoupon();
  },
  methods: {
    loadCoupon() {
      const id = this.$route.params.id;
      this.$Coupons.getResource({ id }).then((response) => {
        this.coupon = response.data;
        this.coupon.typeSelect = {
          label: this.$helper.getEnumTranslation('coupon_type', this.coupon.type, this.$i18n.locale),
          id: this.coupon.type
        }
        this.coupon.variantSelect = {
          id: this.coupon.variant,
          label: this.$helper.getEnumTranslation('coupon_variant', this.coupon.variant, this.$i18n.locale ),
        }
        this.coupon.fromDate = new Date(this.coupon.fromDate)
        this.coupon.toDate = new Date(this.coupon.toDate)
        this.coupon.isGiftVoucher = false
        if (this.coupon.order) {
          this.$Orders.getResourceByUrl({ url: this.coupon.order }, 'voucher_order').then((orderResponse) => {
            if (orderResponse.status === 200) {
              orderResponse.data?.items.forEach(p => {
                // TODO - change
                if (p.product === '/products/123') {
                  this.coupon.isGiftVoucher = true
                }
              })
            }
          })
        }
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
